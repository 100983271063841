import { IError } from '@axmit/error-helper';
import { message } from 'antd';
import { ECommonErrorCode, EErrorStatus } from 'common/models/requestModels';
import { ERoutesCommon } from 'common/models/routesModel';
import { history, store } from 'app/store';
import { clearAuth } from 'app/store/reducers/auth.reducer';
import { EAuthErrorMessage } from 'entities/Auth/Auth.models';

export const errorWatcher = (error: IError | null) => {
  if (!error) {
    return;
  }

  const errorStatus = error.status;

  switch (errorStatus) {
    case EErrorStatus.Unauthorized:
      store.dispatch(clearAuth());

      break;
    case EErrorStatus.Forbidden:
      if (error.data?.code === 'error.userInvalidCredentials') {
        message.error(EAuthErrorMessage.InvalidCreds);
      }

      if (error.data?.code === 'error.userBlockedException') {
        message.error(EAuthErrorMessage.UserBlocked);
      }
      break;
    case EErrorStatus.NotConfirmedEmail:
      message.warning('Please check your email and confirm account!');

      break;
    case EErrorStatus.InternalServerError:
      message.warning('Something went wrong');

      break;
    case EErrorStatus.Validation:
      break;
    case EErrorStatus.PreconditionFailed:
      if (error.data.code === ECommonErrorCode.ReleaseVersionNotSupported) {
        message.warning('Something went wrong, reload page and try again');
      }

      if (error.data.code === ECommonErrorCode.ApiIsUnderMaintenance) {
        const { pathname: currentPath } = window.location;
        currentPath !== ERoutesCommon.TechnicalWork && history.push(ERoutesCommon.TechnicalWork);
      }

      break;
    default:
      break;
  }

  console.log('ERROR', error);
};
