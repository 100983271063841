import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearCreds, getCreds, saveCreds } from '@axmit/axios-patch-jwt';
import { IError } from '@axmit/error-helper';
import { message } from 'antd';
import * as Sentry from '@sentry/react';
import { ERoutesCommon, ERoutesPublic } from 'common/models/routesModel';
import { IBasePathParams } from 'common/models/requestModels';
import { AppDispatch, history, RootState } from 'app/store';
import { authTransport } from 'entities/Auth/Auth.transport';
import {
  EAuthSuccessMessage,
  EBaseErrorMessage,
  IAuthData,
  IAuthModel,
  IAuthPasswordForgotParams,
  IAuthPasswordRestoreParams,
  IOTPKeyPayloadDto,
} from 'entities/Auth/Auth.models';
import { IUserConfirmData, IUserModel } from 'entities/User/User.models';
import { userTransport } from 'entities/User/User.transport';

const LS_2FA_KEY = 'is2faSetupRequired';

export interface IState {
  // auth model
  authModelLoading: boolean;
  authModel: IAuthModel | null;
  authModelParams: IAuthData | null;
  authModelError: IError | null;
  // auth password restore
  authPasswordRestoreLoading: boolean;
  authPasswordRestore: any;
  authPasswordRestoreParams: IAuthPasswordForgotParams | IAuthPasswordRestoreParams | null;
  authPasswordRestoreError: IError | null;
  // auth token check
  authTokenCheckLoading: boolean;
  authTokenCheck: any;
  authTokenCheckError: IError | null;
  // authorized user
  authUserLoading: boolean;
  authUser: IUserModel | null;
  authUserParams: IBasePathParams | null;
  authUserError: IError | null;
  // 2FA
  twoFASetupData: IOTPKeyPayloadDto | null;
  twoFADataLoading: boolean;
  twoFAConfirmationLoading: boolean;
}

const initialState: IState = {
  // auth model
  authModelLoading: true,
  authModel: null,
  authModelParams: null,
  authModelError: null,
  // auth password restore
  authPasswordRestoreLoading: false,
  authPasswordRestore: null,
  authPasswordRestoreParams: null,
  authPasswordRestoreError: null,
  // auth token check
  authTokenCheckLoading: false,
  authTokenCheck: null,
  authTokenCheckError: null,
  // authorized user
  authUserLoading: false,
  authUser: null,
  authUserParams: null,
  authUserError: null,
  // 2FA
  twoFASetupData: null,
  twoFADataLoading: false,
  twoFAConfirmationLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // auth model
    setAuthModelLoading(state, action: PayloadAction<boolean>) {
      state.authModelLoading = action.payload;
    },
    setAuthModel(state, action: PayloadAction<IAuthModel | null>) {
      state.authModel = action.payload;
    },
    setAuthModelParams(state, action: PayloadAction<IAuthData | null>) {
      state.authModelParams = action.payload;
    },
    setAuthModelError(state, action: PayloadAction<IError | null>) {
      state.authModelError = action.payload;
    },
    // auth password restore
    setAuthPasswordRestoreLoading(state, action: PayloadAction<boolean>) {
      state.authPasswordRestoreLoading = action.payload;
    },
    setAuthPasswordRestore(state, action: PayloadAction<any>) {
      state.authPasswordRestore = action.payload;
    },
    setAuthPasswordRestoreParams(state, action: PayloadAction<IAuthPasswordForgotParams | IAuthPasswordRestoreParams | null>) {
      state.authPasswordRestoreParams = action.payload;
    },
    setAuthPasswordRestoreError(state, action: PayloadAction<IError | null>) {
      state.authPasswordRestoreError = action.payload;
    },
    // auth token check
    setAuthTokenCheckLoading(state, action: PayloadAction<boolean>) {
      state.authTokenCheckLoading = action.payload;
    },
    setAuthTokenCheck(state, action: PayloadAction<any>) {
      state.authTokenCheck = action.payload;
    },
    setAuthTokenCheckError(state, action: PayloadAction<IError | null>) {
      state.authTokenCheckError = action.payload;
    },
    // auth model
    setAuthUserLoading(state, action: PayloadAction<boolean>) {
      state.authUserLoading = action.payload;
    },
    setAuthUser(state, action: PayloadAction<IUserModel | null>) {
      state.authUser = action.payload;
    },
    setAuthUserParams(state, action: PayloadAction<IBasePathParams | null>) {
      state.authUserParams = action.payload;
    },
    setAuthUserError(state, action: PayloadAction<IError | null>) {
      state.authUserError = action.payload;
    },
    setTwoFADataLoading(state, action: PayloadAction<boolean>) {
      state.twoFADataLoading = action.payload;
    },
    setTwoFAConfirmationLoading(state, action: PayloadAction<boolean>) {
      state.twoFAConfirmationLoading = action.payload;
    },
    setTwoFASetupData(state, action: PayloadAction<IOTPKeyPayloadDto | null>) {
      state.twoFASetupData = action.payload;
    },
  },
});

export const {
  setAuthModel,
  setAuthModelParams,
  setAuthModelLoading,
  setAuthModelError,
  setAuthTokenCheckLoading,
  setAuthTokenCheckError,
  setAuthPasswordRestoreLoading,
  setAuthPasswordRestoreParams,
  setAuthPasswordRestoreError,
  setAuthUserLoading,
  setAuthUser,
  setAuthUserParams,
  setAuthUserError,
  setTwoFADataLoading,
  setTwoFAConfirmationLoading,
  setTwoFASetupData,
} = authSlice.actions;
export default authSlice.reducer;

const onSuccessAuth = (userId: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(getAuthUser(userId));
  }

  return thunk;
};

export const clearAuth = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthModel(null));
    dispatch(setAuthUser(null));
    dispatch(setTwoFASetupData(null));
    localStorage.removeItem(LS_2FA_KEY);
    clearCreds();
  }

  return thunk;
};

export const initAuthModel = () => {
  async function thunk(dispatch: AppDispatch) {
    try {
      const localAuthModel = await getCreds();
      const userId = localAuthModel?.access?.userId;
      const is2faSetupRequired = localStorage.getItem(LS_2FA_KEY);

      if (userId) {
        dispatch(setAuthModel({ ...localAuthModel, is2faSetupRequired: !!is2faSetupRequired }));
        dispatch(onSuccessAuth(userId));
      }
    } catch (error) {
      history.push(ERoutesCommon.Root);
    } finally {
      dispatch(setAuthModelLoading(false));
    }
  }

  return thunk;
};

export const login = (params: IAuthData) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthModelParams(params));
    dispatch(setAuthModelLoading(true));

    try {
      const authResponse = await authTransport.login(params);

      await saveCreds(authResponse);

      dispatch(setAuthModel(authResponse));

      if (authResponse.is2faSetupRequired) {
        localStorage.setItem(LS_2FA_KEY, 'true');
        authResponse.access && dispatch(onSuccessAuth(authResponse.access?.userId || ''));
        history.push(ERoutesCommon.Root);
      } else {
        localStorage.removeItem(LS_2FA_KEY);
      }
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthModelError(_error));
    } finally {
      dispatch(setAuthModelLoading(false));
    }
  }

  return thunk;
};

export const authWith2FACode = (code: string, successCb: () => void) => {
  async function thunk(dispatch: AppDispatch, getState: () => RootState) {
    dispatch(setTwoFAConfirmationLoading(true));

    try {
      const state = getState();
      const loginParams = state.auth.authModelParams;

      if (!loginParams) {
        return;
      }

      const authModel = await authTransport.authWith2FACode({ ...loginParams, otp: code });

      await saveCreds(authModel);

      if (authModel.is2faSetupRequired) {
        localStorage.setItem(LS_2FA_KEY, 'true');
      } else {
        localStorage.removeItem(LS_2FA_KEY);
      }

      dispatch(setAuthModel(authModel));
      dispatch(onSuccessAuth(authModel.access?.userId || ''));

      successCb();
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthModel(null));
      dispatch(setAuthModelError(_error));

      if (_error?.data?.code === 'error.otpCheckFailedException') {
        message.error('Incorrect code. Please try again');

        return;
      }

      message.error(EBaseErrorMessage.Default);
      Sentry.captureException('Error while authWith2FACode', _error);
    } finally {
      dispatch(setTwoFAConfirmationLoading(false));
    }
  }

  return thunk;
};

export const confirmWith2FACode = (code: string, successCb: () => void) => {
  async function thunk(dispatch: AppDispatch, getState: () => RootState) {
    dispatch(setTwoFAConfirmationLoading(true));

    try {
      const confirmationResponse = await authTransport.confirmWith2FACode(code);
      const state = getState();

      if (confirmationResponse.success) {
        successCb();

        if (state.auth.authModel) {
          await saveCreds(state.auth.authModel);
          localStorage.removeItem('is2faSetupRequired');
          dispatch(setAuthModel({ ...state.auth.authModel, is2faSetupRequired: false }));
        }
      } else {
        message.error('Incorrect code. Please try again');
      }
    } catch (error) {
      const _error = error as IError;

      Sentry.captureException('Error while confirmWith2FACode', _error);

      message.error(EBaseErrorMessage.Default);
    } finally {
      dispatch(setTwoFAConfirmationLoading(false));
    }
  }

  return thunk;
};

export const confirmAuth = (params: IUserConfirmData) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthModelLoading(true));

    try {
      const userResponse = await userTransport.confirm(params);

      await saveCreds(userResponse);
      dispatch(setAuthModel(userResponse));
      dispatch(onSuccessAuth(userResponse.access?.userId || ''));

      message.success(EAuthSuccessMessage.AccountConfirmed);
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthModelError(_error));
    } finally {
      dispatch(setAuthModelLoading(false));
    }
  }

  return thunk;
};

export const get2FASetupData = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setTwoFADataLoading(true));

    try {
      const twoFASetupData = await authTransport.get2FASetupData();

      dispatch(setTwoFASetupData(twoFASetupData));
    } catch (error) {
      const _error = error as IError;

      message.error(EBaseErrorMessage.Default);
    } finally {
      dispatch(setTwoFADataLoading(false));
    }
  }

  return thunk;
};

export const getAuthUser = (userId: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthUserLoading(true));

    try {
      const userModel = await userTransport.get({ id: userId });

      dispatch(setAuthUser(userModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthUserError(_error));

      message.error(EBaseErrorMessage.Default);
      dispatch(clearAuth());
    } finally {
      dispatch(setAuthUserLoading(false));
    }
  }

  return thunk;
};

export const logout = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthModelLoading(true));

    try {
      await authTransport.logout();
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthModelError(_error));
    } finally {
      dispatch(clearAuth());
      dispatch(setAuthModelLoading(false));
    }
  }

  return thunk;
};

export const resetPassword = (params: IAuthPasswordForgotParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthPasswordRestoreLoading(true));
    dispatch(setAuthPasswordRestoreParams(params));

    try {
      await authTransport.passwordForgot(params);

      message.success(EAuthSuccessMessage.PasswordForgot);
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthPasswordRestoreError(_error));
    } finally {
      dispatch(setAuthPasswordRestoreLoading(false));
    }
  }

  return thunk;
};

export const restorePassword = (params: IAuthPasswordRestoreParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthPasswordRestoreLoading(true));
    dispatch(setAuthPasswordRestoreParams(params));

    try {
      await authTransport.passwordRestore(params);

      message.success(EAuthSuccessMessage.ChangePasswordSuccess);
      dispatch(setAuthPasswordRestoreParams(null));
      history.push(ERoutesPublic.Login);
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthPasswordRestoreError(_error));
    } finally {
      dispatch(setAuthPasswordRestoreLoading(false));
    }
  }

  return thunk;
};

export const checkInviteUserToken = (params: { token: string }) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthTokenCheckLoading(true));

    try {
      await userTransport.checkInviteToken(params);
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthTokenCheckError(_error));
    } finally {
      dispatch(setAuthTokenCheckLoading(false));
    }
  }

  return thunk;
};

export const checkPasswordRestoreToken = (params: { token: string }) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthTokenCheckLoading(true));

    try {
      await authTransport.checkInviteToken(params);
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthTokenCheckError(_error));
    } finally {
      dispatch(setAuthTokenCheckLoading(false));
    }
  }

  return thunk;
};
