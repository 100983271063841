import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal } from 'antd';
import Button from 'antd/lib/button';
import { ERoutesPrivate } from 'common/models/routesModel';
import { useAppSelector } from 'app/store/store.hooks';
import { ReactComponent as LockIcon } from 'app/assets/icons/lockIcon.svg';

export const TwoFactorAuthSetupModal: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const is2faSetupRequired = useAppSelector((state) => state.auth.authModel?.is2faSetupRequired);
  const isSettingsPage = location.pathname === ERoutesPrivate.PasswordAndSecurity;

  const goToSettings = () => {
    history.push(`${ERoutesPrivate.PasswordAndSecurity}?tab=twoFASetup`);
  };

  return (
    <Modal
      title="Secure your account"
      open={is2faSetupRequired && !isSettingsPage}
      closable={false}
      maskClosable={false}
      footer={null}
      className="twoFA-modal t-align-c"
    >
      <LockIcon className="mt-4 mb-4" />
      <p>To protect your account and sensitive information, we require you to enable two-factor authentication (2FA).</p>
      <p>This adds an extra layer of security, ensuring your account remains safe even if your password is compromised.</p>
      <div>
        <Button type="primary" onClick={goToSettings}>
          Set Up 2FA
        </Button>
      </div>
    </Modal>
  );
};
