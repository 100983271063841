import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import '@axmit/mb-api';
import { ERoutesCommon, ERoutesFranchiseePrivate, ERoutesPrivate, ERoutesPublic } from 'common/models/routesModel';
import { PermissionLayout } from 'common/components/Layouts/PermissionLayout';
import { Header } from 'common/components/Header/Header';
import { BasicLayout } from 'common/components/Layouts/BasicLayout';
import { RootPublicPage } from 'common/components/Pages/RootPublicPage';
import { RoleCheckLayout } from 'common/components/Layouts/RoleCheckLayout';
import { PublicLayout } from 'common/components/Layouts/PublicLayout';
import { RootFranchiseePage } from 'common/components/Pages/RootFranchiseePage';
import { RootFranchisorPage } from 'common/components/Pages/RootFranchisorPage';
import { HeaderFranchisee } from 'common/components/Header/HeaderFranchisee';
import { RootSalesOpsPage } from 'common/components/Pages/RootSalesOpsPage';
import { MenuPage } from 'app/pages/Franchisor/MenuPage';
import { UserTablePage } from 'app/pages/Franchisor/UserTablePage';
import { StoreTablePage } from 'app/pages/Franchisor/StoreTablePage';
import { StorePage } from 'app/pages/Franchisor/StorePage';
import { UserProfilePage } from 'app/pages/Franchisor/UserProfilePage';
import { CategoryPage } from 'app/pages/Franchisor/CategoryPage';
import { UserPage } from 'app/pages/Franchisor/UserPage';
import { MenuItemPage } from 'app/pages/Franchisor/MenuItemPage';
import { AssetPage } from 'app/pages/Franchisor/AssetPage';
import { StatisticFranchiseePage } from 'app/pages/Franchisee/StatisticFranshiseePage';
import { ProfileFranchiseePage } from 'app/pages/Franchisee/ProfileFranchiseePage';
import { StoreListFranchiseePage } from 'app/pages/Franchisee/StoreListFranchiseePage';
import { CustomerTablePage } from 'app/pages/Franchisor/CustomerTablePage';
import { StoreOrderPage } from 'app/pages/Franchisee/StoreOrderPage';
import { CustomerPage } from 'app/pages/Franchisor/CustomerPage';
import { StatisticFranchisorPage } from 'app/pages/Franchisor/StatisticFranchisorPage';
import { TechnicalWorkPage } from 'app/pages/Common/TechnicalWorkPage';
import { StoreFeedbackPage } from 'app/pages/Franchisee/StoreFeedbackPage';
import { RatingPage } from 'app/pages/Franchisor/RatingPage';
import { RatingTablePage } from 'app/pages/Franchisor/RatingTablePage';
import { PromoTablePage } from 'app/pages/Franchisor/PromoTablePage';
import { PromoPage } from 'app/pages/Franchisor/PromoPage';
import { QRTablePage } from 'app/pages/Franchisor/QRTablePage';
import { QRPage } from 'app/pages/Franchisor/QRPage';
import { StatisticSalesOpsPage } from 'app/pages/SalesOps/StatisticSalesOpsPage';
import { StoreListSalesOpsPage } from 'app/pages/SalesOps/StoreListSalesOpsPage';
import { useAppDispatch } from 'app/store/store.hooks';
import { initAuthModel } from 'app/store/reducers/auth.reducer';
import { PasswordForgotPage } from 'entities/Auth/components/PasswordForgotPage';
import { LoginPage } from 'entities/Auth/components/LoginPage';
import { PasswordRestorePage } from 'entities/Auth/components/PasswordRestorePage';
import { SignUpConfirmPage } from 'entities/Auth/components/SignUpConfirmPage';
import { TwoFactorAuthSetupModal } from 'entities/User/components/TwoFactorAuthSetupModal';
import { EUserRole } from 'entities/User/User.models';

export const App: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initAuthModel());
  }, []);

  return (
    <PermissionLayout
      publicRoutes={
        <Switch>
          <Route path={ERoutesCommon.TechnicalWork} component={TechnicalWorkPage} />
          <Route path={`${ERoutesCommon.Root}*`}>
            <PublicLayout>
              <Switch>
                <Route path={ERoutesPublic.Login} component={LoginPage} />
                <Route path={ERoutesPublic.PasswordForgot} component={PasswordForgotPage} />
                <Route path={ERoutesPublic.PasswordRestore} component={PasswordRestorePage} />
                <Route path={ERoutesPublic.SignUpConfirm} component={SignUpConfirmPage} />
                <Route path={`${ERoutesCommon.Root}*`} component={RootPublicPage} />
              </Switch>
            </PublicLayout>
          </Route>
        </Switch>
      }
      privateRoutes={
        <RoleCheckLayout
          franchisorRoutes={
            <>
              <TwoFactorAuthSetupModal />

              <Switch>
                <Route path={ERoutesCommon.TechnicalWork} component={TechnicalWorkPage} />
                <Route path={`${ERoutesCommon.Root}*`}>
                  <Header />
                  <BasicLayout id="basicLayout" layoutType={EUserRole.Franchisor}>
                    <Switch>
                      <Route path={ERoutesPrivate.Statistics} component={StatisticFranchisorPage} />
                      <Route path={ERoutesPrivate.UsersInvite} component={UserPage} />
                      <Route path={`${ERoutesPrivate.Users}/:id`} component={UserPage} />
                      <Route path={ERoutesPrivate.Users} component={UserTablePage} />
                      <Route path={`${ERoutesPrivate.Rating}/:id`} component={RatingPage} />
                      <Route path={ERoutesPrivate.Rating} component={RatingTablePage} />
                      <Route path={ERoutesPrivate.Menu} component={MenuPage} />
                      <Route path={ERoutesPrivate.CategoryCreate} component={CategoryPage} exact />
                      <Route path={`${ERoutesPrivate.Category}/:id`} component={CategoryPage} exact />
                      <Route path={ERoutesPrivate.AssetCreate} component={AssetPage} exact />
                      <Route path={`${ERoutesPrivate.Asset}/:id`} component={AssetPage} exact />
                      <Route path={ERoutesPrivate.Stores} component={StoreTablePage} exact />
                      <Route path={ERoutesPrivate.StoresCreate} component={StorePage} exact />
                      <Route path={`${ERoutesPrivate.Stores}/:id`} component={StorePage} exact />
                      <Route path={ERoutesPrivate.Promo} component={PromoTablePage} exact />
                      <Route path={ERoutesPrivate.PromoCreate} component={PromoPage} exact />
                      <Route path={`${ERoutesPrivate.Promo}/:id`} component={PromoPage} exact />
                      <Route path={ERoutesPrivate.Customers} component={CustomerTablePage} exact />
                      <Route path={`${ERoutesPrivate.Customers}/:id`} component={CustomerPage} exact />
                      <Route path={ERoutesPrivate.PasswordAndSecurity} component={UserProfilePage} />
                      <Route path={ERoutesPrivate.MenuItemCreate} component={MenuItemPage} exact />
                      <Route path={`${ERoutesPrivate.MenuItem}/:id`} component={MenuItemPage} exact />
                      <Route path={ERoutesPrivate.QR} component={QRTablePage} exact />
                      <Route path={ERoutesPrivate.QRCreate} component={QRPage} exact />
                      <Route path={`${ERoutesPrivate.QR}/:id`} component={QRPage} exact />
                      <Route path={`${ERoutesCommon.Root}*`} component={RootFranchisorPage} />
                    </Switch>
                  </BasicLayout>
                </Route>
              </Switch>
            </>
          }
          franchiseeRoutes={
            <>
              <TwoFactorAuthSetupModal />

              <Switch>
                <Route path={ERoutesCommon.TechnicalWork} component={TechnicalWorkPage} />
                <Route path={`${ERoutesCommon.Root}*`}>
                  <HeaderFranchisee />
                  <BasicLayout id="basicLayout" layoutType={EUserRole.Franchisee}>
                    <Switch>
                      <Route path={ERoutesFranchiseePrivate.StoreList} component={StoreListFranchiseePage} />
                      <Route path={`${ERoutesFranchiseePrivate.StorePage}/:id`} component={StoreOrderPage} />
                      <Route path={ERoutesFranchiseePrivate.Statistics} component={StatisticFranchiseePage} />
                      <Route path={ERoutesFranchiseePrivate.PasswordAndSecurity} component={ProfileFranchiseePage} />
                      <Route path={`${ERoutesFranchiseePrivate.StoreFeedback}/:id`} component={StoreFeedbackPage} />
                      <Route path={`${ERoutesCommon.Root}*`} component={RootFranchiseePage} />
                    </Switch>
                  </BasicLayout>
                </Route>
              </Switch>
            </>
          }
          salesOpsRoutes={
            <>
              <TwoFactorAuthSetupModal />

              <Switch>
                <Route path={ERoutesCommon.TechnicalWork} component={TechnicalWorkPage} />
                <Route path={`${ERoutesCommon.Root}*`}>
                  <HeaderFranchisee />
                  <BasicLayout id="basicLayout" layoutType={EUserRole.SalesOps}>
                    <Switch>
                      <Route path={ERoutesFranchiseePrivate.StoreList} component={StoreListSalesOpsPage} />
                      <Route path={`${ERoutesFranchiseePrivate.StorePage}/:id`} component={StoreOrderPage} />
                      <Route
                        path={`${ERoutesFranchiseePrivate.StoreFeedback}/:id`}
                        render={(props) => <StoreFeedbackPage {...props} isSalesOps={true} />}
                      />
                      <Route path={ERoutesFranchiseePrivate.Statistics} component={StatisticSalesOpsPage} />
                      <Route path={ERoutesFranchiseePrivate.PasswordAndSecurity} component={ProfileFranchiseePage} />
                      <Route path={`${ERoutesCommon.Root}*`} component={RootSalesOpsPage} />
                    </Switch>
                  </BasicLayout>
                </Route>
              </Switch>
            </>
          }
        />
      }
    />
  );
};
