import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { Row } from 'antd/lib/grid';
import { FranchiseeCenterLayout } from 'common/components/Layouts/FranchiseeCenterLayout';
import { UserProfileForm } from 'entities/User/components/Form/UserProfileForm';
import { TwoFactorAuthInstruction } from 'entities/User/components/TwoFactorAuthInstruction';

export const ProfileFranchiseePage: React.FC = () => {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const tabItems: TabsProps['items'] = useMemo(
    () => [
      {
        key: 'changePassword',
        label: 'Change Password',
        children: (
          <FranchiseeCenterLayout>
            <UserProfileForm isFranchisee />
          </FranchiseeCenterLayout>
        ),
      },
      {
        key: 'twoFASetup',
        label: 'Two-factor authentication',
        children: (
          <FranchiseeCenterLayout>
            <TwoFactorAuthInstruction />
          </FranchiseeCenterLayout>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Row justify="center" className="mb-4">
        <h1 className="franchisee__text_title fs-md mt-10" title="Change password">
          Password and Security
        </h1>
      </Row>
      <Tabs defaultActiveKey={queryParams.get('tab') || 'changePassword'} items={tabItems} centered />
    </>
  );
};
