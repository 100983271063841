import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import message from 'antd/lib/message';
import Button from 'antd/lib/button';
import copy from 'copy-to-clipboard';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { ReactComponent as CopyIcon } from 'app/assets/icons/copy.svg';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { confirmWith2FACode, get2FASetupData } from 'app/store/reducers/auth.reducer';
import { ReactComponent as LockIcon } from 'app/assets/icons/lockIcon.svg';

export const TwoFactorAuthInstruction: React.FC = () => {
  const is2faSetupRequired = useAppSelector((state) => state.auth.authModel?.is2faSetupRequired);
  const { twoFADataLoading, twoFAConfirmationLoading, twoFASetupData } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [QRImage, setQRImage] = useState<string | null>(null);
  const [key, setKey] = useState<string | null>(null);
  const [isCodeModalVisible, setIsCodeModalVisible] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');

  const copyLink = (e: Event) => {
    e.stopPropagation();

    if (key) {
      copy(key);
      message.info('Copied to the clipboard');
    }
  };

  const openCodeModal = () => {
    setIsCodeModalVisible(true);
  };

  const closeCodeModal = () => {
    setCode('');
    setIsCodeModalVisible(false);
  };

  const sendCode = () => {
    dispatch(confirmWith2FACode(code, closeCodeModal));
  };

  useEffect(() => {
    if (is2faSetupRequired && !twoFASetupData && !twoFADataLoading) {
      dispatch(get2FASetupData());
    }
  }, []);

  useEffect(() => {
    if (!twoFADataLoading && twoFASetupData) {
      setQRImage(twoFASetupData.qr);
      setKey(twoFASetupData.key);
    }
  }, [twoFASetupData, twoFADataLoading]);

  if (twoFADataLoading) {
    return <LoadingSpin />;
  }

  if (!is2faSetupRequired) {
    return (
      <p className="t-align-c">
        Authenticator app has been set up. We&lsquo;ll ask for a login code when you log in to the service.
      </p>
    );
  }

  return (
    <>
      <Modal
        open={isCodeModalVisible}
        okText="Confirm"
        onOk={sendCode}
        okButtonProps={{ disabled: !code.trim() || twoFAConfirmationLoading }}
        cancelText="Cancel"
        onCancel={closeCodeModal}
        cancelButtonProps={{ disabled: twoFAConfirmationLoading }}
        className="twoFA-modal t-align-c"
      >
        <LockIcon className="mt-4 mb-4" />
        <p>Enter the 6-digit code generated by Google Authenticator</p>

        <Input name="code" type="text" value={code} placeholder="Security code" onChange={(e) => setCode(e.target.value)} />
      </Modal>
      <div className="flex flex__column flex-align-center t-align-c">
        <h3>1. Download an authentication app</h3>
        <p>Download Google Authenticator from Google Play Store or iOS App Store if you don’t have it installed.</p>
        <h3>2. Scan this barcode/QR code or copy the key</h3>
        <p>
          Scan this barcode/QR code in the authentication app or copy the key and paste it in the authentication app (tap the +
          and choose Scan a QR code or Enter a Setup key).
        </p>
        {QRImage && <Image width={200} src={QRImage} preview={false} className="box-with-corner-border" />}
        {key && (
          <h3 className="flex mt-4">
            Key {key} <CopyIcon className="cursor-pointer ml-4" onClick={copyLink} />
          </h3>
        )}
        <h3></h3>
        <p>
          After the barcode/QR code is scanned or the key is entered, your authentication app will generate a 6-digit code. Copy
          the code and come back to enter it.
        </p>
        <div>
          <Button block type="primary" onClick={openCodeModal}>
            Set Up
          </Button>
        </div>
      </div>
    </>
  );
};
