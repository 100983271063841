import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Menu } from 'antd';
import { ERoutesPrivate } from 'common/models/routesModel';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { logout } from 'app/store/reducers/auth.reducer';

export const Header: React.FC = () => {
  // ---> Leave as is. Just to update history and path on-the-go
  useAppSelector((state) => state.router);
  // <---
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { authModelLoading } = useAppSelector((state) => state.auth);
  const path = history.location.pathname;
  const currentPath = path.split(/(^\/[a-zA-Z]*)/)[1];

  const onLogout = () => {
    dispatch(logout());
  };

  const redirect = (e: any) => {
    history.push(e.key);
  };

  return (
    <Menu selectedKeys={[currentPath]} mode="horizontal" className="header-franchisor">
      <Menu.Item key={ERoutesPrivate.Users} title="Users" onClick={redirect} disabled={authModelLoading}>
        <Row justify="center" align="middle" className="width-full">
          Users
        </Row>
      </Menu.Item>
      <Menu.Item key={ERoutesPrivate.Menu} title="Menu" onClick={redirect} disabled={authModelLoading}>
        <Row justify="center" align="middle" className="width-full">
          Menu
        </Row>
      </Menu.Item>
      <Menu.Item key={ERoutesPrivate.Stores} title="Stores" onClick={redirect} disabled={authModelLoading}>
        <Row justify="center" align="middle" className="width-full">
          Stores
        </Row>
      </Menu.Item>
      <Menu.Item key={ERoutesPrivate.Customers} title="Customers" onClick={redirect} disabled={authModelLoading}>
        <Row justify="center" align="middle" className="width-full">
          Customers
        </Row>
      </Menu.Item>
      <Menu.Item key={ERoutesPrivate.Rating} title="Customers feedback" onClick={redirect} disabled={authModelLoading}>
        <Row justify="center" align="middle" className="width-full">
          Customers feedback
        </Row>
      </Menu.Item>
      <Menu.Item key={ERoutesPrivate.Statistics} title="Statistics" onClick={redirect} disabled={authModelLoading}>
        <Row justify="center" align="middle" className="width-full">
          Statistics
        </Row>
      </Menu.Item>
      <Menu.Item key={ERoutesPrivate.QR} title="QRs" onClick={redirect} disabled={authModelLoading}>
        <Row justify="center" align="middle" className="width-full">
          QRs
        </Row>
      </Menu.Item>
      <Menu.Item key={ERoutesPrivate.Promo} title="Promo" onClick={redirect} disabled={authModelLoading}>
        <Row justify="center" align="middle" className="width-full">
          Promo
        </Row>
      </Menu.Item>
      <Menu.Item
        key={ERoutesPrivate.PasswordAndSecurity}
        title="Password and security"
        onClick={redirect}
        disabled={authModelLoading}
      >
        <Row justify="center" align="middle" className="width-full">
          Password and security
        </Row>
      </Menu.Item>
      <Menu.Item className="header__button_logout" key="logout" title="Log Out" onClick={onLogout} disabled={authModelLoading}>
        Log Out
      </Menu.Item>
    </Menu>
  );
};
