import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { Row } from 'antd/lib/grid';
import { ELayoutSize } from 'common/const/common.const';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { UserProfileForm } from 'entities/User/components/Form/UserProfileForm';
import { TwoFactorAuthInstruction } from 'entities/User/components/TwoFactorAuthInstruction';

export const UserProfilePage: React.FC = () => {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const tabItems: TabsProps['items'] = useMemo(
    () => [
      {
        key: 'changePassword',
        label: 'Change Password',
        children: (
          <CommonLayout typeLayout={ELayoutSize.Small} classStyle="basic__layout_m-t">
            <UserProfileForm />
          </CommonLayout>
        ),
      },
      {
        key: 'twoFASetup',
        label: 'Two-factor authentication',
        children: (
          <CommonLayout typeLayout={ELayoutSize.Medium} classStyle="basic__layout_m-t">
            <TwoFactorAuthInstruction />
          </CommonLayout>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Row justify="center" className="mb-4">
        <h1 className="franchisee__text_title fs-md mt-10" title="Change password">
          Password and Security
        </h1>
      </Row>
      <Tabs defaultActiveKey={queryParams.get('tab') || 'changePassword'} items={tabItems} centered />
    </>
  );
};
