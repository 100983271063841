import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import Modal from 'antd/lib/modal';
import { Link } from 'react-router-dom';
import { ERoutesPublic } from 'common/models/routesModel';
import { useFormMapper } from 'common/helpers/form.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { authWith2FACode, login } from 'app/store/reducers/auth.reducer';
import { ReactComponent as LockIcon } from 'app/assets/icons/lockIcon.svg';
import { IAuthData } from 'entities/Auth/Auth.models';

export const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { authModel, authModelLoading, authModelError, authModelParams, twoFAConfirmationLoading } = useAppSelector(
    (state) => state.auth
  );
  const { fields } = useFormMapper(['email', 'password'], null, authModelParams, authModelError);
  const [isCodeModalVisible, setIsCodeModalVisible] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');

  const sendCredentials = (values: any) => {
    const { email, password } = values;
    const params: IAuthData = {
      email,
      password,
    };

    dispatch(login(params));
  };

  const closeCodeModal = () => {
    setCode('');
    setIsCodeModalVisible(false);
  };

  const sendCode = () => {
    dispatch(authWith2FACode(code, closeCodeModal));
  };

  useEffect(() => {
    if (!authModelLoading && authModel && authModel.otpRequired) {
      setIsCodeModalVisible(true);
    }
  }, [authModelLoading, authModel]);

  return (
    <>
      <Modal
        open={isCodeModalVisible}
        okText="Confirm"
        onOk={sendCode}
        okButtonProps={{ disabled: !code.trim() || twoFAConfirmationLoading }}
        cancelText="Cancel"
        onCancel={closeCodeModal}
        className="twoFA-modal t-align-c"
      >
        <LockIcon className="mt-4 mb-4" />
        <p>Enter the 6-digit code generated by Google Authenticator</p>

        <Input name="code" type="text" value={code} placeholder="Security code" onChange={(e) => setCode(e.target.value)} />
      </Modal>

      <Row justify="center" className="mb-4">
        <h1 className="basic__text_title fs-md" title="Log in">
          Log in
        </h1>
      </Row>
      <Form onFinish={sendCredentials} fields={fields} layout="vertical">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Email is required',
            },
          ]}
          name="email"
          label="Email"
        >
          <Input name="email" type="email" placeholder="Email" disabled={authModelLoading} />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Password is required',
            },
          ]}
          name="password"
          label="Password"
        >
          <Input.Password type="password" placeholder="Password" disabled={authModelLoading} />
        </Form.Item>
        <Form.Item>
          <Row justify="space-between" align="middle">
            <Col span={10}>
              <Button
                htmlType="submit"
                block
                type="primary"
                title="Log in"
                disabled={authModelLoading}
                loading={authModelLoading}
              >
                Log in
              </Button>
            </Col>
            <Link
              to={ERoutesPublic.PasswordForgot}
              className="font-family__default fs-xs basic__link ellipsis"
              title="Forgot password?"
            >
              Forgot password?
            </Link>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};
