import axios from 'axios';
import { ICheckTokenData } from 'common/models/commonModel';
import {
  IAuthModel,
  IAuthData,
  IAuthPasswordRestoreParams,
  IAuthPasswordForgotParams,
  IOTPKeyPayloadDto,
  ITwoFAConfirmationResponse,
} from 'entities/Auth/Auth.models';

const basePath = '/auth';
const restorePassword = '/restore-password';

export const authTransport = {
  login: (data: IAuthData): Promise<IAuthModel> => axios.post(basePath, data),
  authWith2FACode: (data: IAuthData): Promise<IAuthModel> => axios.post(basePath, data),
  confirmWith2FACode: (code: string): Promise<ITwoFAConfirmationResponse> => axios.put(`${basePath}/2fa-setup`, { otp: code }),
  get2FASetupData: (): Promise<IOTPKeyPayloadDto> => axios.post(`${basePath}/2fa-setup`),

  logout: (): Promise<void> => axios.delete(basePath),

  passwordForgot: (data: IAuthPasswordForgotParams): Promise<void> => axios.post(restorePassword, data),
  passwordRestore: (data: IAuthPasswordRestoreParams): Promise<void> => axios.put(restorePassword, data),

  checkInviteToken: (data: ICheckTokenData): Promise<void> => axios.post(`${restorePassword}/check-token`, data),
};
