import React from 'react';
import { useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { ERoutesFranchiseePrivate } from 'common/models/routesModel';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { logout } from 'app/store/reducers/auth.reducer';
import { EUserRole, EUserRoleText } from 'entities/User/User.models';

interface IComponentProps {
  mode?: 'horizontal' | 'vertical';
  mobile?: boolean;
  close?: () => void;
}

export const HeaderMenu: React.FC<IComponentProps> = ({ mobile }) => {
  // ---> Leave as is. Just to update history and path on-the-go
  useAppSelector((state) => state.router);
  // <---
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { authModel, authUser } = useAppSelector((state) => state.auth);
  const authorized = authModel && Object.keys(authModel).length !== 0;
  const visibleLogout = authorized && mobile;
  const path = history.location.pathname;
  const currentPath = path.split(/(^\/[a-z-A-Z]*)/)[1];
  const showLogo = !mobile;
  const mobileClass = mobile ? 'header-franchisee-menu_mobile' : '';
  const mode = mobile ? 'vertical' : 'horizontal';
  const isSalesOp = authUser?.role === EUserRole.SalesOps;
  const logoRole = isSalesOp ? EUserRoleText.SalesOps : EUserRoleText.Franchisee;

  const onLogout = () => {
    close && close();
    dispatch(logout());
  };

  const redirect = (e: MenuInfo) => {
    close && close();
    history.push(e.key.toString());
  };

  const redirectToMain = () => {
    close && close();
    history.push(ERoutesFranchiseePrivate.StoreList);
  };

  return (
    <Menu selectedKeys={[currentPath]} mode={mode} className={`header-franchisee-menu ${mobileClass}`}>
      {showLogo && (
        <Menu.Item key="logo" onClick={redirectToMain}>
          <span className="header-franchisee__title">MB</span>
          <span className="header-franchisee__title_role"> — {logoRole}</span>
        </Menu.Item>
      )}

      <Menu.Item key={ERoutesFranchiseePrivate.StoreList} onClick={redirect} className="header-franchisee-menu__activilable-item">
        Stores
      </Menu.Item>
      <Menu.Item
        key={ERoutesFranchiseePrivate.Statistics}
        onClick={redirect}
        className="header-franchisee-menu__activilable-item"
      >
        Statistics
      </Menu.Item>
      <Menu.Item
        key={ERoutesFranchiseePrivate.PasswordAndSecurity}
        onClick={redirect}
        className="header-franchisee-menu__activilable-item"
      >
        Password And Security
      </Menu.Item>

      {visibleLogout && (
        <Menu.Item key="logout" onClick={onLogout} className="header-franchisee-menu__activilable-item">
          Log out
        </Menu.Item>
      )}
    </Menu>
  );
};
