import React from 'react';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { useAppSelector } from 'app/store/store.hooks';

interface IComponentProps {
  privateRoutes: React.ReactElement;
  publicRoutes: React.ReactElement;
}
export const PermissionLayout: React.FC<IComponentProps> = ({ privateRoutes, publicRoutes }) => {
  const { authModel, authModelLoading } = useAppSelector((state) => state.auth);
  const authorized = authModel && authModel.access;

  return authModelLoading ? <LoadingSpin /> : authorized ? privateRoutes : publicRoutes;
};
