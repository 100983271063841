export interface ITokenModel extends Components.Schemas.TokenPayloadDto {}
export interface IAuthModel extends Components.Schemas.AuthPayloadDto {}
export interface IAuthData extends Components.Schemas.CredentialsDto {}
export interface IAuthPasswordForgotParams extends Components.Schemas.PasswordForgotDto {}
export interface IAuthPasswordRestoreParams extends Components.Schemas.PasswordRecoveryDto {}
export interface IOTPKeyPayloadDto extends Components.Schemas.OTPKeyPayloadDto {}
export interface ITwoFAConfirmationResponse extends Components.Schemas.OtpConfirmResultDto {}

export enum EAuthSuccessMessage {
  AccountConfirmed = 'Account successfully confirmed',
  PasswordForgot = 'Check email for password restore link',
  ChangePasswordSuccess = 'Password successfully changed',
}

export enum EAuthErrorMessage {
  InvalidRestoreCode = 'Invalid code. Try again.',
  InvalidCreds = 'Incorrect email or password.',
  UserBlocked = 'Your account has been suspended. If you think it is a mistake, please contact the administrator.',
}

export enum EBaseErrorMessage {
  Default = 'An error occurred, please try again later',
}
